import { render, staticRenderFns } from "./PaperPoints.vue?vue&type=template&id=35d82897&scoped=true&"
import script from "./PaperPoints.vue?vue&type=script&lang=js&"
export * from "./PaperPoints.vue?vue&type=script&lang=js&"
import style0 from "./PaperPoints.vue?vue&type=style&index=0&id=35d82897&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d82897",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VIcon,VRadio,VRadioGroup,VSelect})
