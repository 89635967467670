//import state from './state';

export default {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setCurrentUser(state, user) {
    state.isLoggedIn = true;
    state.user = user;
  },
  removeCurrentUser(state) {
    state.isLoggedIn = false;
    state.user = {};
  },
  setProjects(state, projects) {
    if(projects) state.projects = projects;
    else state.projects = []
  },
  setProjectYears(state, years) {
    state.projectYears = years;
  },
  setCurrentProjectYear(state, year) {
    state.currentProjectYear = year;
  },
  addProject(state, data) {
    state.projects.push(data);
  },
  removeProject(state, pid) {
    var removeId = -1;
    for (var i = 0; i < state.projects.length; i++) {
      if (pid == state.projects[i].pid) {
        removeId = i;
        break;
      }
    }
    if (removeId != -1) {
      state.projects.splice(removeId);
    }
  },

  setProjectTimeline(state, timeline) {
    var newTimeline = {};
    for (var time in timeline) {
      if (typeof timeline[time] == 'string') {
        newTimeline[time] = timeline[time].split('T')[0];
      } else {
        newTimeline[time] = timeline[time];
      }
    }
    state.stageTimeline = newTimeline;
  },

  patchProject(state, pid, project) {
    var patchId = -1;
    for (var i = 0; i < state.projects.length; i++) {
      if (pid == state.projects[i].pid) {
        patchId = i;
        break;
      }
    }
    if (patchId != -1) {
      state.projects.splice(patchId, 1, project);
    }
  },
  setAnnouncementTab(state, selectedTab) {
    state.selectedAnnouncementTab = selectedTab;
  },
  setAnnouncementList(state, data) {
    state.announcementList = data;
  },
  setAcademicData(state, data) {
    // console.log('academic', data);
    state.academicData = data;
  },
  setErrorHappenend(state, isErrorHappenend){
    state.isErrorHappenend = isErrorHappenend;
  },
  setWarningText(state, warningText){
    state.warningText = warningText;
  },
  setNotify(state, isNotify ){
    state.isNotify = isNotify;
  },
  setNotificationText(state, notificationText){
    state.notificationText = notificationText;
  },
  setCurrentProjectStage(state, stage){
    state.currentProjectStage = stage
  }
  // setPersonalData(state, data) {
  //   console.log('personal', data);
  //   state.personalData = data;
  // },
  // setCourseData(state, data) {
  //   console.log('courseData', data);
  //   state.courseData = data;
  // },
};
