<template>
  <div id="stage-two">
    <Stepper step="two" @changeYear="(year) => this.changeYear(year)"/>
    <template v-if="this.isStudent">
      <SortTable :student_uid="this.currentUID" ref="studentSortTable"/>
    </template>
    
    <template v-else-if="this.isAdmin">
      <div class="s2-admin-container">
        <div class="s2-admin-student-search">
        <input v-model="searchText" class="s2-search" placeholder="學生學號或姓名" />
        </div>
        <table class="s2-admin-table">
          <thead>
            <tr> 
              <th> 學號 </th> 
              <th> 學生姓名 </th>
              <th> 狀態 </th> 
            </tr>
          </thead>
          <tbody>
            <tr v-for="(element, id) in sortedStudentList" :key="id" v-bind:style="rowStyle(id)" v-on:click="adminSelect(id)">
              <td class="s2-td-500">{{ element.uid }}</td>
              <td class="s2-td-500">{{ element.name }}</td>
              <td v-if="checkComplete(element.status)" class="s2-td-complete-text">{{ element.status }}</td>
              <td v-else class="s2-td-incomplete-text">{{ element.status }}</td>
            </tr>
          </tbody>
        </table>
        <div class="s2-pagination-container">
          <Pagination :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
        </div>
      </div>
      
      <div v-show="adminSelectedId != -1" class="s2-dynamic-container">
        <div class="s2-dynamic-table">
          <div class="s2-dynamic-name-tag">{{ adminSelectUid }}</div>
          <SortTable :student_uid="this.adminSelectUid" ref="adminSortTable" @refreshstatusable="(year) => this.mountGetAllStudentPriorityStatus(year)"/>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="project-empty-container">
        <div class="project-alert" >
          <v-icon class="red-text">mdi-alert-circle</v-icon>
            目前你沒有權限進行觀看
        </div>
    </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Stepper from '../../components/ProjectManage/Steppers.vue'
import SortTable from '../../components/ProjectManage/SortTable.vue'
import Pagination from '../../components/ProjectManage/Pagination.vue'
export default {
  name: 'stageTwo',
  components: {
    Stepper,
    SortTable,
    Pagination,
  },
  async mounted(){
    if(this.isAdmin){
      this.mountGetAllStudentPriorityStatus(this.currentProjectYear);
    }
  },
  data(){
    return{
      searchText:"",
      adminSelectedId: -1,
      adminSelectUid: "",
      studentList:[],
      bufferSearch: [],
      totalPage: 1,
      limit: 2,
      offset: 0,
      awaitingSearch: false,
    }
  },
  watch: {
    searchText:{
      handler(){
        if(!this.awaitingSearch){
          setTimeout(() => {
            let all = this.studentList.concat(this.bufferSearch)
            this.studentList = []
            this.bufferSearch = []
            all.forEach(ele => {
              if(ele.name.toLowerCase().includes(this.searchText.toLowerCase()) || 
              ele.uid.toLowerCase().includes(this.searchText.toLowerCase())){
                this.studentList.push(ele)
              }
              else{
                this.bufferSearch.push(ele)
              }
            })
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
        
      }
    }
  },
  methods:{
    ...mapActions(['setCurrentProjectYear']),
    changePage(offset){
      this.offset = offset
      this.adminSelect(-1);
      this.mountGetAllStudentPriorityStatus(this.currentProjectYear)
    },
    async changeYear(year){
      await this.setCurrentProjectYear(year);
      // load sort table for different auth
      if(this.isStudent) {
        await this.$refs.studentSortTable.getselectedPriority(this.currentProjectYear, this.currentUID)
        await this.$refs.studentSortTable.getCandidateTeacher(this.currentProjectYear)
      }
      else if (this.isAdmin) {
        this.adminSelect(-1);
        this.mountGetAllStudentPriorityStatus(this.currentProjectYear);
      }
    },
    async adminSelect(id){
      this.adminSelectedId = id;
      if(this.adminSelectedId != -1){
        this.adminSelectUid = this.sortedStudentList[id].uid;
      // call child function if select different student
      await this.$refs.adminSortTable.getselectedPriority(this.currentProjectYear, this.adminSelectUid)
      await this.$refs.adminSortTable.getCandidateTeacher(this.currentProjectYear)
      }
    },
    rowStyle(id){
      if(id == this.adminSelectedId) return {"background-color": "var(--v-lightGrey-base)"}
      else return {"background-color": "var(--v-lightGrey2-base)"}
    },
    checkComplete(status){
      if(status == "Incomplete") return false
      else return true
    },
    sortByIncomplete(a, b){
      let key = "status"
      if(b[key] == "Incomplete") return 1
      else return -1
    },
    async mountGetAllStudentPriorityStatus(year){
      this.studentList = []
      var allStudent = await this.$services.projectManage.getAllStudentPriorityStatus(year, this.offset, this.limit);
      this.totalPage = allStudent.total_page
      allStudent.data.forEach( async  element => {
        console.log(element)
        if(element.is_created_priority){
          await this.studentList.push({
            "uid": element.uid,
            "name": element.name,
            "status": "Complete"
        })}
        else{
          this.studentList.push({
            "uid": element.uid,
            "name": element.name,
            "status": "Incomplete"
          })
        }})
      // return this.sortedStudentList[this.adminSelectedId].uid
    },
  },
  computed: {
    sortedStudentList() {
      return JSON.parse(JSON.stringify([...this.studentList].sort(this.sortByIncomplete)))
    },
    ...mapGetters(['isAdmin', 'isStudent', 'isTeacher', 'currentName', 'currentUID', 'currentStatus', 'currentProjectYear']),
  }
};
</script>
<style scoped>
.red-text{
  color: var(--v-red-base);;
}
.description{
  text-align: center;
}
.v-btn.v-size--default {
  font-size: 1.2rem;
}
#stage-two{
  padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  overflow-x:hidden;
  overflow-y:auto;
}

.s2-admin-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: space-around; 
  flex-direction: row;
  flex-wrap: wrap;
}
.s2-admin-student-search{
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  border: solid 2px var(--v-blue-base);
  border: 2px 2px 0px 2px;
  border-bottom-color: transparent;
  background-color: var(--v-white-base);
  margin-bottom: 2px;
}
.s2-search{
  color: var(--v-lightGrey-base);
  width: 700px;
  padding: 5px;
  outline: none;
}
::placeholder {
  color: var(--v-lightGrey-base);;
  opacity: 1; /* Firefox */
}
table {
  border: 3px solid var(--v-blue-base);
  color: var(--v-darkGrey-base);
  background-color: #fff;
  width:40%;
  font-weight: bold;
}
th {
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
}
tr{
  background-color: var(--v-lightGrey2-base);;
}
tr:hover{
  background-color: var(--v-lightGrey-base);
  cursor: pointer;
}
th, td {
  min-width:120px;
  padding: 10px 20px;
}
.s2-admin-table{
  width: 100%;
  height: 250px; 
  overflow-x:hidden;
  overflow-y:auto;
}
.s2-td-complete-text{
  width: 500px;
  color:var(--v-green-base)
}
.s2-td-incomplete-text{
  width: 500px;
  color:var(--v-red-base)
}
.s2-td-500{
  width: 500px;
}
.s2-pagination-container{
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
.s2-dynamic-container{
  margin-top: 50px;
  width: 100%;
}
.s2-dynamic-table{
  border-radius: 20px;
  padding-bottom: 30px;
}
.s2-dynamic-name-tag{
  border-radius: 8px;
  height: 50px;
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  font-size: 25px;
  margin-bottom: 30px;
  text-align: center;
  padding: 8px;
  font-weight: bold;
  letter-spacing: 0.58px;;
}
.project-empty-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: center; 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.project-alert{
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.3px;
  text-align: center;
  color: #fd9e9e;
}

</style>
