<template>
  <div id="history-project">
    <div class="his-pro-title">專題查詢</div>
    <div class="his-pro-search-basic">
      <input v-model="keyword" class="his-pro-input" style="width:700px" placeholder="關鍵字 (專題題目名稱)" />
      <v-divider class="his-pro-divider" vertical></v-divider>
      <input v-model="teacherName" class="his-pro-input" style="width:300px" placeholder="教授名稱" />
      <v-divider class="his-pro-divider" vertical></v-divider> 
      <v-select class="his-pro-select" flat solo
          :items="years" 
          v-model="year"
          label="年份"
        ></v-select>
        <v-btn color="#ffbc6f" class="his-pro-search-btn" @click="searchProject()"> 搜尋</v-btn>
    </div>
    <div class="his-pro-pro-container">
      <div v-for="pro in projects" :key="pro.index">
        <Project :project="pro" />
      </div>
      <Pagination :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Project from '../components/HistoryProject/ProjectBrowse.vue'
import Pagination from '../components/ProjectManage/Pagination.vue';
export default {
  name: 'historyProject',
  components: {
    Project,
    Pagination
  },
  async mounted(){
    var projects = await this.$services.historyProject.getAllHistoryProject(this.offset, this.limit, new Date().getFullYear().toString())
    this.projects = projects.data
    this.totalPage = projects.total_page
    this.years = await this.$services.historyProject.getProjectYear();
  },
  data(){
    return{
      years:[],
      projects:[],
      year:"",
      keyword: "",
      teacherName: "",
      totalPage: 1,
      limit: 5,
      offset: 0,
    }
  },
  computed: {
    ...mapGetters(['currentName', 'currentUID', 'currentStatus', 'currentRole', 'currentProjectYear']),
  },
  methods:{
    changePage(offset){
      this.offset = offset
      this.searchProject();
    },
    async searchProject(){
      var projects = []
      if(this.year){
        projects = await this.$services.historyProject.getHistoryProjectByYear(this.year.toString(), this.keyword, this.teacherName, this.offset, this.limit)
        this.projects = projects.data
        this.totalPage = projects.total_page
      }
      else{
        projects = await this.$services.historyProject.getHistoryProject(new Date().getFullYear().toString(), this.year, this.keyword, this.offset, this.limit)
        this.projects = projects.data
        this.totalPage = projects.total_page
      }
      
    }
  }
};
</script>
<style scoped>
#history-project{
  padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
}
.his-pro-title{
  font-size: 16px;
  letter-spacing: 1.54px;
  text-align: left;
  color: #a6a6a6;
  margin-bottom: 10px;
}
.his-pro-searchbg{
  margin-top: 32px;
}
.his-pro-search-basic{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  height: 64px;
  padding: 0 10px 10 10;
  border-radius: 12px;
  border: solid 2px rgba(92, 92, 92, 0.14);
  background-color: #ffffff;
}
.his-pro-search-btn{
  width: 100px;
  height: 30px;
  margin: 10px;
  padding: 6px 32px 6px 33px;
  border-radius: 12px;
}
.his-pro-divider{
  margin-right: 20px;
}
.his-pro-input{
  color: black;
  padding: 20px;
  width: 700px;
  outline: none;
}
.his-pro-select{
  margin-top: 5px;
}
input::-webkit-input-placeholder {
  text-align: left;
}
.his-pro-pro-container{
  margin-top: 50px;
  width: 100%;
}
</style>