<template>
  <div id="login-container" class="d-flex flex-column justify-center">
    <div
      id="login-background"
      :style="`background-image: url(${require('@/assets/ntu.jpeg')})`"
    ></div>
    <div class="text-h2">
      臺大資管
    </div>
    <div class="text-h1">
      INTRANET
    </div>
    <div>
      請使用臺大計中帳號登入
    </div>
    <div class="mt-4">
      <v-btn dark @click="redirect('https://internal.im.ntu.edu.tw/api/v1/auth/login')" class="ma-2">
        SSO Login
      </v-btn>
    </div>
  </div>
</template>
<style scoped lang="scss">
#login-container {
  height: 100%;
  color: white;
  div {
    z-index: 1;
  }
  #login-background {
    filter: blur(5px);
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'Home',
  methods: {
    ...mapActions(['login', 'logout']),
    redirect(url) {
      location = url;
    }
  },
  async mounted(){
    this.$services.auth.getInfo().then(res=>{
      this.login({
        user: res.user,
        redirect: this.$route.query.redirect
      });
    }).catch(err=>{
      console.log('err', err);
      this.logout();
    });
  }
};
</script>
