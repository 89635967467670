export default class AdministrationService {
  constructor(api) {
    this.api = api;
  }
  getQualifiedStudents() {
    return this.api.get('/project/record/new');
  }
  getAllTeachers() {
    return this.api.get('/user/profile/public/search', {
      status: 'teacher',
    });
  }
  searchUserByKeyword(keyword, status) {
    return this.api.get('/user/profile/public/search', {
      keyword: keyword,
      status: status,
    });
  }
  createProjectConfig(projectConfig) {
    return this.api.post('/project/config', projectConfig);
  }
  createStudentRecord(studentList) {
    return this.api.post('/project/record/batch', studentList);
  }
  editStudentRecord(oldNewList) {
    return this.api.patch('/project/record/batch', oldNewList);
  }
  createProjectsByProfessors(professors) {
    return this.api.post('/project/batch', {
      teacher_uids: professors,
    });
  }
  editProfessors(oldNewList) {
    return this.api.patch('/project/batch', oldNewList);
  }
  getCurrentProjectRecords(year) {
    return this.api.get('/project/record', {
      year: year,
    });
  }
  createUser(users) {
    return this.api.post('/user/batch', users);
  }
}
