import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Home from '@/views/Home';
import Announcement from '@/views/Announcement/Announcement';
import AnnouncementList from '@/views/Announcement/AnnouncementList';
import AnnouncementDetail from '@/views/Announcement/AnnouncementDetail';
import GlobalSearch from '@/views/GlobalSearch';
import Profile from '@/views/Profile';
import HistoryProject from '@/views/HistoryProject';
import AcademyMaster from '@/views/Academy/Master';
import StageOne from '@/views/ProjectManage/StageOne';
import StageTwo from '@/views/ProjectManage/StageTwo';
import StageThree from '@/views/ProjectManage/StageThree';
import StageFour from '@/views/ProjectManage/StageFour';
import AcademyDoctor from '@/views/Academy/Doctor';
import Project from '@/views/Project';
import Administration from '@/views/Administration';

Vue.use(VueRouter);

const guard = (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters.loggedIn;
  if (authRequired && !loggedIn) {
    return next({
      path: '/',
      query: {
        redirect: to.path,
      },
    });
  }
  next();
};

const routes = [
  { name: 'home', path: '/', component: Home },
  {
    name: 'announcement',
    path: '/Announcement',
    redirect: '/Announcement/list/newest',
    component: Announcement,
    children: [
      {
        name: 'announcement-list',
        path: 'list/:tab',
        component: AnnouncementList,
      },
      {
        name: 'announcement-detail',
        path: 'detail/:id',
        component: AnnouncementDetail,
      },
    ],
  },

  { name: 'global-search', path: '/GlobalSearch', component: GlobalSearch },
  { name: 'profile', path: '/Profile/:id', component: Profile },
  { name: 'project', path: '/Project', component: HistoryProject },
  { name: 'projectView', path: '/project/:pid', component: Project },
  { name: 'stageOne', path: '/projectManage/stageOne', component: StageOne },
  { name: 'stageTwo', path: '/projectManage/stageTwo', component: StageTwo },
  { name: 'stageFour', path: '/projectManage/stageFour', component: StageFour },
  {
    name: 'stageThree',
    path: '/projectManage/stageThree',
    component: StageThree,
  },
  { name: 'academy-master', path: '/Master/:id', component: AcademyMaster },
  { name: 'academy-doctor', path: '/Doctor/:id', component: AcademyDoctor },
  {
    name: 'administration',
    path: '/Administration',
    component: Administration,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(guard);

export default router;
