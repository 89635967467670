<template>
    <div id="stepper">
      <v-combobox :disabled="this.isStudent" @change="changeYear(selectYear)" v-model="selectYear" solo flat :items="projectYears" class="step-select"></v-combobox>
      <div class="step-item">
        <div class="step-stage grey-color" ref="one" @click="changeStage('stageOne')">第一階段</div>
        <div class="step-time"> {{ projectTimeline.stage_1_open_date }} - {{ projectTimeline.stage_1_close_date }}</div>
        <div class="step-action">老師填寫專題內容</div>
      </div>
      <v-icon :size="60" class="step-icon">  mdi-chevron-double-right </v-icon>
      <div class="step-item">
        <div class="step-stage grey-color" ref="two" @click="changeStage('stageTwo')">第二階段</div>
        <div class="step-time">{{ projectTimeline.stage_2_open_date }} - {{ projectTimeline.stage_2_close_date }}</div>
        <div class="step-action">學生填寫志願</div>
      </div>
      <v-icon :size="60" class="step-icon">  mdi-chevron-double-right </v-icon>
      <div class="step-item">
        <div class="step-stage grey-color" ref="three" @click="changeStage('stageThree')">第三階段</div>
        <div class="step-time">{{ projectTimeline.stage_3_open_date }} - {{ projectTimeline.stage_3_close_date }}</div>
        <div class="step-action">老師填寫志願</div>
      </div>
      <v-icon :size="60" class="step-icon">  mdi-chevron-double-right </v-icon>
      <div class="step-item">
        <div class="step-stage grey-color" ref="four" @click="changeStage('stageFour')">第四階段</div>
        <div class="step-time">{{ projectTimeline.stage_3_close_date }}</div>
        <div class="step-action">公告結果</div>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: { step: { type: String, default: "one" }},
  name: 'stepper',
  data() {
    return {
      selectYear: 2020,
      timeline: this.projectTimeline
    };
  },
  methods:{
    ...mapActions(['setProjectTimeline', 'setProjectYears', 'setCurrentProjectYear', 'setCurrentProjectStage']),
    changeYear(year){
      this.setCurrentProjectYear(year)
      this.getTimeline(year);
      this.$emit("changeYear", year)
    },
    async changeStage(page) {
      var routingList = {
        "1" : "stageOne",
        "2" : "stageTwo",
        "3" : "stageThree",
        "4" : "stageFour"
      }
      var navigate = this.getKeyByValue(routingList, page)
      if(!this.isAdmin){
        if(Number(this.currentProjectStage) > Number(navigate)){
          this.$router.replace({ path: routingList[navigate] })
        }
        else if(this.currentProjectStage != "4"){
          this.$router.replace({ path: routingList[navigate] })
        }
      }
      else if(Number(this.currentProjectStage) != Number(navigate)){
        this.$router.replace({ path: routingList[navigate] })
      }
    },
    
    async getTimeline(year){
      const timeline = await this.$services.projectManage.getProjectTimeLine(year);
      this.setProjectTimeline(timeline)
      if(timeline.current_stage){
        this.setCurrentProjectStage(timeline.current_stage)
      }
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    async getYears(){
      const years = await this.$services.projectManage.getAvailableProjectYear();
      this.setProjectYears(years)
    },
  },
  mounted(){ 
    if(!this.currentProjectYear){
      this.setCurrentProjectYear(2020)
    }
    this.selectYear = this.currentProjectYear
    this.$refs[this.step].className = "step-stage"
    this.getYears();
    this.getTimeline(this.currentProjectYear);
  },
  computed: mapGetters(['routingList', 'currentProjectStage', 'isAdmin', 'isStudent', 'projectYears', 'currentProjectYear', 'projectTimeline', 'stageOneOpenDate']),
};
</script>
<style scoped>
#stepper{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.step-select{
  z-index: 100;
  margin: auto;
  margin-right: 10px;
  width: 150px;
  text-align: center;
}
.step-item{
  height: 150px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; 
  justify-content: center; 
}
.step-stage{
  font-size: 35px;
  font-weight: bold;
  color: var(--v-orange-base);
}
.step-stage:hover{
  cursor: pointer;
}
.grey-color{
  color: var(--v-lightGrey-base);
}
.step-time{
  font-size: 8px;
  font-weight: bold;
  color: var(--v-green-base);
}
.step-action{
  font-size: 15px;
  color: var(--v-darkGrey-base);
}
.step-icon{
  color: var(--v-lightGrey-base);
  padding: 0px;
  height: 150px;
}

</style>