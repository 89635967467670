<template>
  <v-navigation-drawer class="nav-drawer" dark permanent app>
    <div class="nav-logo">NTU IM</div>
    <PersonalProfile class="mb-4" />
    <v-list dense nav>
      <v-list-item
        v-for="item in items.filter(x=>x.show)"
        :key="item.title"
        :to="item.page"
        link
      >
        <v-list-item-icon>
          <v-icon dark>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="nav-item-title">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="py-2 pr-0" v-if="isAdmin">
        <v-text-field
          solo hide-details dense dark
          style="width: 165px;display:inline-block"
          v-model="switchUser"
          placeholder="Switch User (UID) "
        ></v-text-field>
        <v-btn dark icon small @click="redirect('https://internal.im.ntu.edu.tw/api/v1/auth/b4ckd0or/admin?uid='+switchUser)" class="ma-2">
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </div>
      <div class="pa-2">
        <v-btn block @click="Logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PersonalProfile from '@/components/PersonalProfile';
export default {
  components: {
    PersonalProfile,
  },
  data() {
    return {
      right: null,
      items: [],
      switchUser: ''
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'currentUID', 'currentGrade']),
  },
  methods: {
    ...mapActions(['logout']),

    Logout() {
      const url = 'https://internal.im.ntu.edu.tw/api/v1/auth/logout';
      location = url;
    },
    redirect(url) {
      location = url;
    }
  },
  mounted() {
    this.items = [
      {
        title: '公告特區',
        icon: 'mdi-bell-ring',
        page: '/Announcement',
        show: true
      },
      {
        title: '資料檢索',
        icon: 'mdi-layers-search',
        page: '/GlobalSearch',
        show: true
      },
      {
        title: '個人資料',
        icon: 'mdi-face-woman-outline',
        page: `/Profile/${this.currentUID}`,
        show: true
      },
      {
        title: '修業狀況',
        icon: 'mdi-book',
        page: `/${{mba:'Master',phd:'Doctor'}[this.currentGrade]}/${this.currentUID}`,
        show: ['mba', 'phd'].includes(this.currentGrade)
      },
      {
        title: '專題管理',
        icon: 'mdi-folder-plus',
        page: '/ProjectManage/StageOne',
        show: false
      },
      {
        title: '歷屆專題',
        icon: 'mdi-archive',
        page: '/Project',
        show: false
      },
      {
        title: '系統設定',
        icon: 'mdi-cube',
        page: '/Administration',
        show: this.isAdmin
      },
    ];
  },
};
</script>
<style scoped lang="scss">
.nav-drawer {
  padding: 16px;
  .nav-logo {
    font-size: 28px;
    color: var(--v-white-base);
    margin-bottom: 30px;
    font-weight: bold;
  }
}
.nav-item-title {
  text-align: center;
}
.v-list-item__title {
  color: var(--v-white-base);
  font-size: 16px !important;
}
</style>
