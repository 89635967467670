<template>
	<div class="s4-admin-container">
		<div class="s4-admin-student-search">
		<input v-model="searchText" class="s4-search" placeholder="學生學號或姓名" />
		</div>
		<table class="s4-admin-table">
			<thead>
				<tr> 
					<th> 學號 </th> 
					<th> 學生姓名 </th>
					<th> 專題 - 教授 </th> 
					<th></th> 
				</tr>
			</thead>
			<tbody>
				<tr v-for="(element, id) in studentList" :key="id" v-bind:style="rowStyle(id)" v-on:click="adminSelect(id)">
					<td class="s4-td-200">{{ element.student_uid }}</td>
					<td class="s4-td-200">{{ element.student_name }}</td>
					
					<td class="s4-td-200" v-if="element.edit">
             <v-select class="ma-0 pa-0" flat :items="projectList" 
            item-value="pid" item-text="title" v-model="element.pid">
            </v-select>
          </td>
					<td class="s4-td-200" v-else> {{element.project_name}} - {{ element.teacher_name }}</td>
					<td>
					<v-img 
						v-if="!element.edit"
						:src="require('../../assets/red-edit.svg')"
						class="professor-edit" 
						contain
						@click.native="studentList[id].edit = true"
					/>
					<v-icon :size="33" class="professor-save"
						v-else
						@click="savePair(id, element)">  
						mdi-checkbox-marked-circle 
					</v-icon>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="s4-pagination-container">
      <Pagination :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Pagination from '../../components/ProjectManage/Pagination.vue'
export default {
  name: 'PairTable',
  components:{
    Pagination
  },
  async mounted(){
    var allProjects = await this.$services.projectManage.getAllTeacherProject(this.currentProjectYear);
    this.projectList = allProjects.data
    this.getAllStudent(this.currentProjectYear, (this.page-1)*this.displayCnt, this.displayCnt)
  },
  data() {
    return {
      page: 1,
      displayCnt: 10,
      searchText:"",
      resultTitle: "最終結果",
      adminSelectedId:0,
      projectList:[],
      studentList:[],
      bufferSearch: [],
      totalPage: 1,
      limit: 5,
      offset: 0,
    };
  },
  watch:{
    async page(oldValue, newValue){
      this.studentList = []
      this.getAllStudent(this.currentProjectYear, (newValue-1)*this.displayCnt, this.displayCnt)
    },
    searchText:{
      handler(){
        let all = this.studentList.concat(this.bufferSearch)
        this.studentList = []
        this.bufferSearch = []
        all.forEach(ele => {
          if(ele.student_name.toLowerCase().includes(this.searchText.toLowerCase()) || 
          ele.student_uid.toLowerCase().includes(this.searchText.toLowerCase()) ){
            this.studentList.push(ele)
          }
          else{
            this.bufferSearch.push(ele)
          }
        })
      }
    },
  },
  computed: mapGetters(['currentName', 'currentUID', 'currentProjectYear']),
	methods:{
    changePage(offset){
      this.offset = offset
      this.getAllStudent(this.currentProjectYear)
    },
    async changeYear(year){
      var allProjects = await this.$services.projectManage.getAllTeacherProject(year);
      this.projectList = allProjects.data
      this.getAllStudent(year)
    },
    async savePair(id, pair){
      this.studentList[id].edit = false;
      for(var i = 0; i <= this.projectList.length; i++){
        if(this.projectList[i].pid == this.studentList[id].pid){
          console.log(this.projectList[i])
          console.log(this.studentList[id])
          this.studentList[id].project_name = this.projectList[i].title 
          this.studentList[id].teacher_name = this.projectList[i].teacher_name
          break
        }
      }
      await this.$services.projectManage.patchProjectRecord(pair.student_uid, this.currentProjectYear, this.studentList[id].pid)
    },
    checkComplete(status){
      if(status == "Incomplete") return false
      else return true
    },
    adminSelect(id){
      this.adminSelectedId = id;
    },
    rowStyle(id){
      if(id == this.adminSelectedId) return {"background-color": "var(--v-lightGrey-base)"}
      else return { "background-color": "var(--v-lightGrey2-base)"}
    },
    statusStyle(status){
      if(status.toLowerCase() != "complete") return {"color": "var(--v-red-base)"}
      else return { "color": "var(--v-green-base)"}
    },
    async getAllStudent(year){
      this.studentList = []
      var studentRecord = await this.$services.projectManage.getAllProjectRecord(year, this.offset, this.limit)
      this.totalPage = studentRecord.total_page
      studentRecord.data.forEach((ele) => {
        if(ele.pid){
          this.studentList.push({
          "student_name": ele.student_name,
          "student_uid": ele.student_uid,
          "teacher_uid": ele.teacher_uid,
          "teacher_name": ele.teacher_name,
          "project_name": ele.project_name,
          "pid": ele.pid,
          "status": "complete",
          "edit": false
          })
        }
        else{
          this.studentList.push({
          "student_name": ele.student_name,
          "student_uid": ele.student_uid,
          "status": "Incomplete",
          "edit": false
          })
        }
        
      })
    },
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border: 3px solid var(--v-blue-base);
  color: var(--v-darkGrey-base);
  background-color: #fff;
  font-weight: bold;
}

th {
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
}
tr{
  width: 100%;
  background-color: var(--v-lightGrey2-base);
  cursor: pointer;
}
th, td {
  padding: 10px 20px;
}
.s4-admin-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: space-around; 
  flex-direction: row;
  flex-wrap: wrap;
}
.s4-admin-student-search{
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  border: solid 2px var(--v-blue-base);
  border: 2px 2px 0px 2px;
  border-bottom-color: transparent;
  background-color: var(--v-white-base);
  margin-bottom: 2px;
}
.s4-search{
  color: var(--v-lightGrey-base);
  width: 700px;
  padding: 5px;
  outline: none;
}
::placeholder {
  color: var(--v-lightGrey-base);;
  opacity: 1; /* Firefox */
}
.s4-admin-table{
  width: 100%;
  height: 250px; 
  overflow-x:hidden;
  overflow-y:auto;
}
.s4-admin-selected-table{
  width: 100%;
  height: 50px; 
  overflow-x:hidden;
  overflow-y:auto;
  margin-bottom: 30px;
}
.s4-td-complete-text{
  width: 500px;
  color:var(--v-green-base)
}
.s4-td-incomplete-text{
  width: 500px;
  color:var(--v-red-base)
}
.s4-td-200{
  width: 200px;
}

.professor-edit{
  height: 30px;
  display: flex;
  z-index: 10;
}
.professor-edit:hover{
  cursor: pointer;
}
.professor-save{
  color:var(--v-blue-base); 
  align-self: flex-end; 
  margin-right: 5px;
}
.professor-save:hover{
  cursor: pointer;
}

.s4-td-select{
  background: transparent;
  width: 150px;
  margin: 0px;
  height: 50px;
}
.theme--light .v-text-field--solo > .v-input__control > .v-input__slot{
  background: transparent;
}
.s4-pagination-container{
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}
</style>
