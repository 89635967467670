export default class AcademicService {
  constructor(api) {
    this.api = api;
  }
  getAcademic(grade, uid) {
    if (grade === 'mba') {
      return this.api.get(`/academic/mba/${uid}`);
    } else if (grade === 'phd') {
      console.log('uidd', uid);
      return this.api.get(`/academic/phd/${uid}`);
    }
  }
  saveBasicCourse(grade, uid, basicCourse) {
    if (grade === 'mba') {
      return this.api.patch(`/academic/mba/course/${uid}`, basicCourse);
    } else if (grade === 'phd') {
      return this.api.patch(`/academic/phd/course/${uid}`, basicCourse);
    }
  }
  saveProposal(grade, uid, proposal) {
    console.log('grade', grade);
    if (grade === 'mba') {
      return this.api.patch(`/academic/mba/proposal/${uid}`, proposal);
    } else if (grade === 'phd') {
      return this.api.patch(`/academic/phd/proposal/${uid}`, proposal);
    }
  }
  saveEnglishTest(grade, uid, englishTest) {
    if (grade === 'mba') {
      return this.api.patch(`/academic/mba/english/${uid}`, englishTest);
    } else if (grade === 'phd') {
      return this.api.patch(`/academic/phd/english/${uid}`, englishTest);
    }
  }
  saveExam(grade, uid, exam) {
    if (grade === 'mba') {
      return this.api.patch(`/academic/mba/exam/${uid}`, exam);
    } else if (grade === 'phd') {
      return this.api.patch(`/academic/phd/exam/${uid}`, exam);
    }
  }
  saveBasicCoursePhd(uid, coursePhd) {
    return this.api.patch(`/academic/phd/basic/${uid}`, coursePhd);
  }
  saveQualifiedExam(uid, qualifyExam) {
    return this.api.patch(`/academic/phd/qualify/${uid}`, qualifyExam);
  }
  saveOralExam(uid, oralExam) {
    return this.api.patch(`/academic/phd/oral/${uid}`, oralExam);
  }
  saveProgress(uid, progress) {
    return this.api.patch(`/academic/phd/progress/${uid}`, progress);
  }
  savePaperPoints(uid, points) {
    return this.api.patch(`/academic/phd/point/${uid}`, points);
  }
  savePresentation(uid, presentation) {
    return this.api.patch(`/academic/phd/presentation/${uid}`, presentation);
  }
}
