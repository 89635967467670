<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <input
        v-model="date"
        v-bind="attrs"
        v-on="on"
        class="info-item-input"
      />
    </template>
    <v-date-picker v-model="date" no-title scrollable :color="color" @change="dateChange">
      <v-spacer></v-spacer>
      <v-btn text :color="color" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text :color="color" @click="saveDate">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    datePicked: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'mdi-calendar',
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    iconResult() {
      return this.isIcon ? this.icon : '';
    },
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
    };
  },
  methods: {
    saveDate() {
      this.$refs.menu.save(this.date);
      this.$emit('saveDate', this.date, this.idx);
    },
    dateChange(date){
      var [year, month, day] = date.split('-');
      if(parseInt(year) < 1990){
        this.date = `${(new Date().getFullYear())}-${month}-${day}`;
      }
    }
  },
  mounted() {
    this.date = this.datePicked;
  },
};
</script>

<style scoped lang="scss">
.info-item-input {
  width: 100%;
  height: 36px;
  border: 2px solid var(--v-lightGrey-base);
  border-radius: 5px;
  color: var(--v-darkGrey-base);
  padding: 5px;
  &:focus {
    border: 2px solid var(--v-blue-base);
    color: var(--v-darkGrey-base);
  }
}
</style>