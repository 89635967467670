<template>
  <div id="project-manage">
    <Stepper @changeYear="(year) => this.getStageOneProject(year)"/>
    <div class="project-empty-container" v-show="this.projects == null">
        <div class="project-alert" >
          <v-icon style="color:#fd9e9e;">mdi-alert-circle</v-icon>
            目前尚未新增 {{ this.currentProjectYear - 1911 }} 學年度專題內容
        </div>
    </div>

    <template v-if="this.isAdmin">
      <div v-for="pro in projects" :key="pro.index">
        <Project :project="pro" :hasAuthToEdit="true"/>
      </div>
      <div class="project-btn-container">
        <v-btn height="50" width="300" color="#9ed3fd" rounded dark @click.stop="isDialogOpen = true">新增專題內容+</v-btn>
        <ProjecEditDialog :isDialogOpen="isDialogOpen" @closeProjectDialog="() => this.isDialogOpen = false"/>
        <v-overlay v-show="isDialogOpen" :z-index="10" :value="isDialogOpen" @click.native="isDialogOpen = false"></v-overlay>
      </div>
      <Pagination :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
    </template>

    <template v-else-if="this.isTeacher">
      <div v-for="pro in projects" :key="pro.pid">
        <Project v-if="pro.teacher_uid == currentUID" :project="pro" :hasAuthToEdit="true"/>
        <Project v-else :project="pro" :hasAuthToEdit="false" />
      </div>
      <div class="project-btn-container">
        <v-btn height="50" width="300" color="#9ed3fd" rounded dark @click.stop="isDialogOpen = true">新增專題內容+</v-btn>
        <ProjecEditDialog :isDialogOpen="isDialogOpen" @closeProjectDialog="() => this.isDialogOpen = false"/>
        <v-overlay v-show="isDialogOpen" :z-index="10" :value="isDialogOpen" @click.native="isDialogOpen = false"></v-overlay>
      </div>
    </template>

    <template v-else>
      <div v-for="pro in this.projects" :key="pro.index">
        <Project  :project="pro" :hasAuthToEdit="false"/>
      </div>
    </template>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Stepper from '../../components/ProjectManage/Steppers.vue'
import Project from '../../components/ProjectManage/Project.vue'
import ProjecEditDialog from '../../components/ProjectManage/ProjectEditDialog.vue'
import Pagination from '../../components/ProjectManage/Pagination.vue';
export default {
  name: 'stageOne',
  components: {
    Stepper,
    Project,
    ProjecEditDialog,
    Pagination
  },
  data(){
    return{
      isDialogOpen: false,
      totalPage: 1,
      limit: 5,
      offset: 0,
    }
  },
  methods: {
    ...mapActions(['setProjects', 'setCurrentProjectYear', 'setCurrentProjectStage']),
    changePage(offset){
      this.offset = offset
      this.getStageOneProject(this.currentProjectYear)
    },
    async getStageOneProject(){
      const data = await this.$services.projectManage.getStageOneProject(this.currentProjectYear, this.offset, this.limit);
      this.totalPage = await data.total_page
      this.setProjects(data.data);
    },
    async setStage(){
      const timeline = await this.$services.projectManage.getProjectTimeLine(this.currentProjectYear);
      if(timeline.current_stage){
        this.setCurrentProjectStage(timeline.current_stage)
      }
    }
  },
  async mounted(){
    this.setStage();
    this.getStageOneProject();
  },
  computed: {
    ...mapGetters(['routingList', 'currentProjectStage', 'isAdmin', 'isStudent', 'isTeacher', 'currentName', 'currentUID', 'projects', 'currentProjectYear']),
  }
};
</script>
<style scoped>
.v-btn.v-size--default {
  font-size: 1.2rem;
}
#project-manage{
  padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  overflow-x:hidden;
  overflow-y:auto;
}
.project-alert{
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.3px;
  text-align: center;
  color: #fd9e9e;
  margin-top: 100px;
}
.project-empty-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: center; 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.project-btn-container{
  margin: 50px;
  align-self: center;
}

</style>
