import { render, staticRenderFns } from "./ProjectEditDialog.vue?vue&type=template&id=237242e3&scoped=true&"
import script from "./ProjectEditDialog.vue?vue&type=script&lang=js&"
export * from "./ProjectEditDialog.vue?vue&type=script&lang=js&"
import style0 from "./ProjectEditDialog.vue?vue&type=style&index=0&id=237242e3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237242e3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverflowBtn } from 'vuetify/lib/components/VOverflowBtn';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VDialog,VImg,VOverflowBtn,VTextarea})
