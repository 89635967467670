import router from '@/router';
// import { notificationText } from './getters';
//import axios from 'axios';
//import APIURL from '../url.js';

export const login = ({ commit }, { user, redirect }) => {
    commit('setCurrentUser', {
        name: user.name,
        uid: user.uid,
        status: user.status,
        auth: user.auth,
        grade: user.grade,
        email: user.email,
        photo: user.photo,
        gender: user.gender,
    });
    redirect = redirect || 'announcement';
    router.push({ path: redirect });
};

export const logout = ({ commit }) => {
    commit('removeCurrentUser');
    router.push('/').catch(() => {});
};

export const setProjects = ({ commit }, projects) => {
    commit('setProjects', projects);
};

export const setProjectTimeline = ({ commit }, timeline) => {
    commit('setProjectTimeline', timeline);
};

export const setProjectYears = ({ commit }, years) => {
    commit('setProjectYears', years);
};

export const setCurrentProjectYear = ({ commit }, year) => {
    commit('setCurrentProjectYear', year);
};

export const addProject = async({ commit }, project) => {
    commit('addProject', project);
};

export const deleteProjectOnStageOne = async({ commit }, pid) => {
    commit('removeProject', pid);
};

export const patchProjectOnStageOne = async({ commit }, pid, project) => {
    commit('patchProject', pid, project);
};

export const changeAnnouncementTab = ({ commit }, selectedTab) => {
    commit('setAnnouncementTab', selectedTab);
};

export const setAnnouncementList = ({ commit }, announcementList) => {
    commit('setAnnouncementList', announcementList);
};

export const setAcademicData = ({ commit }, academicData) => {
    commit('setAcademicData', academicData);
};

export const setError = ({ commit }, isErrorHappenend) => {
    commit('setErrorHappenend', isErrorHappenend);
};

export const setErrorText = ({ commit }, warningText) => {
    commit('setWarningText', warningText);
    commit('setErrorHappenend', true);
};

export const setNotify = ({ commit }, isNotify) => {
    commit('setNotify', isNotify)
}

export const setNotificationText = ({ commit }, notificationText) => {
    commit('setNotificationText', notificationText)
}

export const setCurrentProjectStage = ({ commit }, stage) => {
        commit('setCurrentProjectStage', stage)
    }
    // export const setPersonalData = ({ commit }, personalData) => {
    //   commit('setPersonalData', personalData);
    // };

// export const setCourseData = ({ commit }, courseData) => {
//   commit('setCourseData', courseData);
// };