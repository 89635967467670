<template>
  <div id="stage-three">
    <Stepper step="three" @changeYear="(year) => this.changeYear(year)" />
    <div class="project-empty-container" v-show="this.courseCode.length == 0 & !this.isStudent">
        <div class="project-alert" >
          <v-icon style="color:#fd9e9e;">mdi-alert-circle</v-icon>
            目前尚未新增 {{ this.currentProjectYear - 1911 }} 學年度專題內容
        </div>
    </div>
    <template v-if="this.isTeacher">
      <v-expansion-panels popout >
        <v-expansion-panel
          v-for="(item,i) in courseCode"
          v-show="item.teacher_uid == currentUID"
          :key="i"
        >
        <v-expansion-panel-header class="s3-expansion-panel">
          專題代碼 : {{ item.pid }} - {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="s3-expansion-panel">
          <StudentOrderTable :project="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>      
    </template>

    <template v-else-if="this.isAdmin">
      <v-expansion-panels popout >
        <v-expansion-panel
          v-for="(item,i) in courseCode"
          :key="i"
        >
        <v-expansion-panel-header class="s3-expansion-panel">
          專題代碼 : {{ item.pid }} - {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="s3-expansion-panel">
          <StudentOrderTable :project="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>      
    </template>

    <template v-else>
      <div class="project-empty-container">
        <div class="project-alert" >
          <v-icon style="color:#fd9e9e;">mdi-alert-circle</v-icon>
            目前你沒有權限進行觀看
        </div>
    </div>
    </template>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Stepper from '../../components/ProjectManage/Steppers.vue'
import StudentOrderTable from '../../components/ProjectManage/StudentOrderTable.vue'
export default {
  name: 'stageThree',
  components: {
    Stepper,
    StudentOrderTable
  },
  data(){
    return{
      professorName: "小傑老師",
      courseCode:[],
    }
  },
  computed: mapGetters(['isAdmin', 'isStudent', 'isTeacher', 'currentName', 'currentUID', 'currentStatus', 'currentProjectYear']),
  async mounted(){
    this.setCourseCode()
  },
  methods:{
    ...mapActions(['setCurrentProjectYear']),
    async setCourseCode(){
      var allCourse = {}
      if(this.isTeacher){
        allCourse = await this.$services.projectManage.getTeacherOwnProject(this.currentProjectYear, this.currentUID)
        this.courseCode = allCourse.data
      }
      else if(this.isAdmin){
        allCourse = await this.$services.projectManage.getAllTeacherProject(this.currentProjectYear)
        this.courseCode = allCourse.data
      }
    },
    async changeYear(year){
      await this.setCurrentProjectYear(year);
      this.setCourseCode()
    }
  }
};
</script>
<style scoped lang="scss">
.v-btn.v-size--default {
  font-size: 1.2rem;
}
#stage-three{
  padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x:hidden;
  overflow-y:auto;
}
.s3-expansion-panel{
  background-color:var(--v-lightGrey2-base);
  font-weight: bold;
}
.project-empty-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: center; 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.project-alert{
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.3px;
  text-align: center;
  color: #fd9e9e;
  margin-top: 100px;
}
</style>
