<template>
  <div class="text-center ma-2">
    <v-snackbar class="float"
      v-model="this.isErrorHappenend"
    >
      {{ warningText }}

      <template>
        <v-btn
          color="pink"
          text
          @click="setError(false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters, mapActions  } from 'vuex'
export default {
  name: 'errorModal',
  methods:{
    ...mapActions(['setError'])
  },
  computed: {
    ...mapGetters(['isErrorHappenend', 'warningText']),
  },
  created(){
    return{
      test: true
    }
  }
}
</script>
<style lang="scss" scoped>
.float{
  z-index: 10;
}
</style>