<template>
	<table class="s4-order-table">
		<thead>
			<tr> <th> {{ resultTitle }} -  {{professorName}} </th> </tr>
		</thead>
		<tbody>
			<div>
				<tr v-for="element in selected" :key="element.id">
					<td >{{ element.student_uid }}</td>
					<td class="s4-order-table-name-column">
						{{ element.student_name }}
					</td>
				</tr>
			</div>
		</tbody>
	</table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'resultTable',
  props: {
    pid: { type: Number, default: 0 },
  },
  computed: mapGetters(['isTeacher', 'isStudent',  'currentName', 'currentUID', 'currentStatus', 'currentProjectYear']),
  async mounted(){
    if(this.pid){
      var project = await this.$services.projectManage.getProjectByPid(this.pid, this.currentProjectYear)
      this.selected = project.data
      if(this.selected.length > 0) this.professorName = this.selected[0].teacher_name + this.pid.toString()
    }
  },
	data(){
    return{
      professorName: "",
			resultTitle: "最終結果",
			selected:[],
		}
	},
  methods:{
    async changeYear(){
      var project = await this.$services.projectManage.getProjectByPid(this.pid, this.currentProjectYear)
      this.selected = project.data
    }
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border: 3px solid var(--v-blue-base);
  color: var(--v-darkGrey-base);
  background-color: #fff;
  font-weight: bold;
}

th {
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
}
tr{
  width: 100%;
  background-color: var(--v-lightGrey2-base);
  cursor: pointer;
}
th, td {
  padding: 10px 20px;
}
.s4-order-table{
  width: 100%; 
  max-width: 100%; 
  max-height: 150px; 
  overflow-x:hidden;
  overflow-y:auto;
}
.s4-order-table-name-column{
  width: 100%
}
</style>
