<template>
  <div id="stage-four">
    <Stepper step="four" @changeYear="(year) => this.changeYear(year)"/>
    <template v-if="this.isTeacher">
      <v-expansion-panels popout >
        <v-expansion-panel
          v-for="(item,i) in courseCode"
          v-show="item.teacher_uid == currentUID"
          :key="i"
        >
        <v-expansion-panel-header class="s4-expansion-panel">
          {{ item.year }}  {{ item.teacher_name }} 專題 :  - {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="s4-expansion-panel">
          <ResultTable ref="resultTableForTeacher" :pid="item.pid"/>
        </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>

    <template v-else-if="this.isAdmin">
      <v-btn v-on:click="distribute()" class="s4-distribute-btn" 
      color=var(--v-red-base) elevation="2" height="50px"> 
        自動分配 
      </v-btn>
      <v-card width="100%" class="s4-tab-container">
      <v-tabs v-model="tab" background-color="transparent" color="blue" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item class="s4-tab-item-container">
          <PairTable ref="pairTable" />
        </v-tab-item>
        <v-tab-item class="s4-tab-item-container" >
          <PairPreviewTable ref="adminPairPreviewTable" />
        </v-tab-item>
      </v-tabs-items>
      </v-card>
    </template>

    <template v-else>
      <ResultTable ref="resultTableForStudent" :pid="studentPId"/>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PairPreviewTable from '../../components/ProjectManage/PairPreviewTable.vue'
import Stepper from '../../components/ProjectManage/Steppers.vue'
import ResultTable from '../../components/ProjectManage/ResultTable.vue'
import PairTable from '../../components/ProjectManage/PairTable.vue'
export default {
  name: 'stageFour',
  components: {
    Stepper,
    ResultTable,
    PairTable,
    PairPreviewTable,
  },
  async mounted(){
    this.getResultForST()
  },
  data() {
    return {
      page:4,
      courseCode:[],
      items: ["編輯", "預覽"],
      tab: "編輯",
      studentPId : ""
    };
  },
  watch:{
    tab(){
      this.changeYear(this.currentProjectYear)
    }
  },
  methods:{
    async getResultForST(){
      if(this.isTeacher){
        var teacherOwnPro =  await this.$services.projectManage.getTeacherOwnProject(this.currentProjectYear, this.currentUID);
        this.courseCode = teacherOwnPro.data
      }
      else if (this.isStudent){
        var studentOwnPro = this.$services.projectManage.getSelfProjectByStudentId(this.currentUID, this.currentProjectYear)
        if(studentOwnPro.data) this.studentPId = studentOwnPro.data.at(-1).pid
      }
    },
    async distribute(){
      await this.$services.projectManage.distribute(this.currentProjectYear);
      this.changeYear(this.currentProjectYear)
    },
    async changeYear(year){
      this.getResultForST()
      if(this.isAdmin){
        await this.$refs.pairTable.changeYear(year)
        await this.$refs.adminPairPreviewTable.getAllStudent(year);
      }
      else if(this.isTeacher){
        this.$refs.resultTableForTeacher.changeYear(year)
      }
      else{
        this.$refs.resultTableForStudent.changeYear(year)
      }
    }
  },
  computed: mapGetters(['isAdmin', 'isStudent', 'isTeacher', 'currentName', 'currentUID', 'currentProjectYear']),
};
</script>
<style lang="scss" scoped>
#stage-four{
  padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  overflow-x:hidden;
  overflow-y:auto;
}
.s4-expansion-panel{
  font-weight: bold;
  background-color:var(--v-lightGrey2-base);
}
.s4-tab-container{
  margin-top: 50px;
  border-radius: 20px 20px 5px 5px;
}
.s4-tab-item-container{
  padding-top: 50px;
  padding-bottom: 20px; 
}
.s4-distribute-btn{
	width: 150px;
	color: var(--v-white-base);
	font-size: 20px;
	font-weight: bold;
	border-radius: 15px;
	height: 50px;
	background-color: var(--v-purple-base);
}
.project-empty-container{
  width: 100%;
  align-content: center;
  align-items: center; 
  justify-content: center; 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.project-alert{
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.3px;
  text-align: center;
  color: #fd9e9e;
  margin-top: 100px;
}
</style>