<template>
  <table>
    <thead>
      <tr>
        <th colspan="5" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>{{
              grade === 'mba' ? '碩士論文學位考試' : '博士論文學位考試'
            }}</span>
            <v-icon
              v-if="!isEditMode && (isAdmin)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>期限</th>
        <th>狀態</th>
        <th class="paper-note">論文題目</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr
        v-for="(item, idx) in academicData.degree_exam_details"
        :key="item.subject"
      >
        <td>{{ item.deadline }}</td>
        <td>{{ researchResultOptions[item.confirm] }}</td>
        <td>
          {{ item.subject ? item.subject : '' }}
        </td>
        <td>
          {{ item.date }}
        </td>
        <td
          v-if="idx === 0 && academicData.degree_exam_details"
          :rowspan="academicData.degree_exam_details.length"
        >
          {{ overallResultOptions[degreeExamConfirm] }}
        </td>
      </tr>
      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.degree_exam_note ? academicData.degree_exam_note : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="(item, idx) in academicData.degree_exam_details" :key="idx">
        <td>
          <DatePicker
            color="green"
            @saveDate="saveDeadlineDate"
            :datePicked="item.deadline"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="item.confirm">
            <v-radio
              v-for="n in researchResultOptions.length"
              :key="n"
              :label="researchResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="green"
            ></v-radio>
          </v-radio-group>
        </td>
        <td>
          <div class="note-textarea">
            <textarea placeholder="請輸入論文題目" v-model="item.subject" />
          </div>
        </td>
        <td>
          <DatePicker
            color="green"
            @saveDate="saveDate"
            :datePicked="item.date"
            :idx="idx"
          />
        </td>
        <td
          v-if="idx === 0 && academicData.degree_exam_details"
          :rowspan="academicData.degree_exam_details.length"
        >
          <v-radio-group v-model="degreeExamConfirm">
            <v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="green"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>

      <tr
        v-if="
          academicData.degree_exam_details &&
            academicData.degree_exam_details.length < 2
        "
      >
        <td colspan="5">
          <v-icon small color="#FFFFFF" class="plus-btn" @click="addExam">
            mdi-plus
          </v-icon>
        </td>
      </tr>

      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.degree_exam_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
const researchResultOptions = ['不通過', '通過'];
export default {
  props: {
    grade: {
      type: String,
      default: '',
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,

      researchResultOptions: researchResultOptions,
      overallResultOptions: ['警告', '通過'],

      // researchResult: '',
      // paperDetail: '',
      // deadlineDatePicked: '',
      // datePicked: '',
      // note: '',
      // overallResult: '',
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
    degreeExamConfirm: {
      get: function() {
        return this.academicData.degree_exam_confirm == undefined
          ? undefined
          : this.academicData.degree_exam_confirm == false
          ? 0
          : 1;
      },
      set: function(value) {
        this.academicData.degree_exam_confirm = value === 1 ? true : false;
      },
    },
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDeadlineDate(date, idx) {
      this.academicData.degree_exam_details[idx].deadline = date;
    },
    saveDate(date, idx) {
      this.academicData.degree_exam_details[idx].date = date;
    },
    addExam() {
      this.academicData.degree_exam_details.push({
        uid: this.academicData.uid,
        subject: '',
        date: moment().format('YYYY-MM-DD'),
        deadline: moment().format('YYYY-MM-DD'),
        confirm: undefined,
      });
    },
    async saveEdit() {
      this.academicData.degree_exam_details = this.academicData.degree_exam_details.filter(
        exam => exam.subject,
      );
      const examList = this.academicData.degree_exam_details.map(exam => {
        return {
          uid: exam.uid,
          subject: exam.subject,
          date: moment(exam.date).format('YYYY-MM-DDT23:59:59') + 'Z',
          deadline: moment(exam.deadline).format('YYYY-MM-DDT23:59:59') + 'Z',
          confirm: !!exam.confirm
        };
      });
      const exam = {
        Exams: examList,
        degree_exam_confirm: this.academicData.degree_exam_confirm,
        degree_exam_note: this.academicData.degree_exam_note,
      };
      console.log('ecam', exam);
      const res = await this.$services.academic.saveExam(
        this.grade,
        this.academicData.uid,
        exam,
      );
      console.log('res', res);
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
</style>
