<template>
  <table>
    <thead>
      <tr>
        <th colspan="4" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>課程與學分</span>
            <v-icon
              v-if="!isEditMode && (isAdmin && false)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin && false"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>應修學分</th>
        <th>已修學分</th>
        <th colspan="2">系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr>
        <td>
          共同必修 ({{ academicData.course_config.course_compulsory_goal }})
        </td>
        <td>
          {{
            academicData.course_compulsory ? academicData.course_compulsory : 0
          }}
        </td>
        <td>
          {{ resultOptions[academicData.course_compulsory_confirm ? 1 : 0] }}
        </td>
        <td rowspan="3">{{ resultOptions[overallResult ? 1 : 0] }}</td>
      </tr>
      <tr>
        <td>
          指導教授指定課程 ({{
            academicData.course_config.course_must_elective_goal
          }})
        </td>
        <td>
          {{
            academicData.course_must_elective
              ? academicData.course_must_elective
              : 0
          }}
        </td>
        <td>
          {{ resultOptions[academicData.course_must_elective_confirm ? 1 : 0] }}
        </td>
      </tr>
      <tr>
        <td>
          選修課程 ({{ academicData.course_config.course_elective_goal }})
        </td>
        <td>
          {{ academicData.course_elective ? academicData.course_elective : 0 }}
        </td>
        <td>
          {{ resultOptions[academicData.course_elective_confirm ? 1 : 0] }}
        </td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          {{ academicData.course_note ? academicData.course_note : '/' }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>
          必修課程 ({{ academicData.course_config.course_compulsory_goal }})
        </td>
        <td><input v-model="academicData.course_compulsory" /></td>
        <td class="radio-cell">
          <v-radio-group v-model="courseCompulsoryConfirm">
            <v-radio
              v-for="n in resultOptions.length"
              :key="n"
              :label="resultOptions[n - 1]"
              :value="n - 1"
              class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
        <td rowspan="3">
          <div class="radio-cell">
            <v-radio-group v-model="overallResult">
              <v-radio
                v-for="n in resultOptions.length"
                :key="n"
                :label="resultOptions[n - 1]"
                :value="n - 1"
                class="radio-style"
                color="blue"
                disabled
              ></v-radio>
            </v-radio-group>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          指導教授指定課程 ({{
            academicData.course_config.course_must_elective_goal
          }})
        </td>
        <td><input v-model="academicData.course_must_elective" /></td>
        <td class="radio-cell">
          <v-radio-group v-model="courseMustElectiveConfirm">
            <v-radio
              v-for="n in resultOptions.length"
              :key="n"
              :label="resultOptions[n - 1]"
              :value="n - 1"
              class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td>
          選修課程 ({{ academicData.course_config.course_elective_goal }})
        </td>
        <td><input v-model="academicData.course_elective" /></td>
        <td class="radio-cell">
          <v-radio-group v-model="courseElectiveConfirm">
            <v-radio
              v-for="n in resultOptions.length"
              :key="n"
              :label="resultOptions[n - 1]"
              :value="n - 1"
              class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.course_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      isEditMode: false,

      resultOptions: ['警告', '通過'],
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
    courseCompulsoryConfirm: {
      get: function() {
        return this.academicData.course_compulsory_confirm ? 1 : 0;
      },
      set: function(newValue) {
        this.academicData.course_compulsory_confirm =
          newValue === 1 ? true : false;
      },
    },
    courseMustElectiveConfirm: {
      get: function() {
        return this.academicData.course_must_elective_confirm ? 1 : 0;
      },
      set: function(newValue) {
        this.academicData.course_must_elective_confirm =
          newValue === 1 ? true : false;
      },
    },
    courseElectiveConfirm: {
      get: function() {
        return this.academicData.course_elective_confirm ? 1 : 0;
      },
      set: function(newValue) {
        this.academicData.course_elective_confirm =
          newValue === 1 ? true : false;
      },
    },
    overallResult() {
      if (
        this.academicData.course_compulsory_confirm &&
        this.academicData.course_must_elective_confirm &&
        this.academicData.course_elective_confirm
      ) {
        return 1;
      }
      return 0;
    },
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    async saveEdit() {
      const basicCourse = {
        course_compulsory: parseInt(this.academicData.course_compulsory),
        course_must_elective: parseInt(this.academicData.course_must_elective),
        course_elective: parseInt(this.academicData.course_elective),
        course_compulsory_confirm: this.academicData.course_compulsory_confirm,
        course_must_elective_confirm: this.academicData.course_must_elective_confirm,
        course_elective_confirm: this.academicData.course_elective_confirm,
        course_note: this.academicData.course_note,
      };

      await this.$services.academic.saveBasicCourse(
        'phd',
        this.academicData.uid,
        basicCourse,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/academy.scss';
</style>
