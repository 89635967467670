<template>
  <div id="doctor">
    <div class="profile-public-container">
      <div class="profile-pic-container">
        <img :src="this.photo" :onerror="`this.src='${this.defaultProfile}'`" />
      </div>
      <div class="profile-public-info-container">
        <div class="profile-public-label">基本資料</div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label">姓名</div>
            <div class="item-content">{{ academicData.name }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">學號</div>
            <div class="item-content">{{ academicData.uid }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">年級</div>
            <div class="item-content">{{ grade }}</div>
          </div>
        </div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label item-label-longer">入學年度</div>
            <div class="item-content">{{ academicData.entry_year }}</div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">畢業年限</div>
            <div class="item-content">
              {{ academicData.graduate_year_limit }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">休學狀態</div>
            <div class="item-content">{{ dropStatus }}</div>
          </div>
        </div>
      </div>
    </div>

    <BasicCourse />
    <BasicCoursePhd />
    <CourseTest />
    <EnglishTest />
    <OralExam />
    <PaperProgress />
    <PaperOutcome grade="phd" />
    <PaperPoints />
    <Publish />
    <ResearchTest grade="phd" />
  </div>
</template>

<script>
import editIcon from '@/assets/edit_icon.png';
import config from '../../../config';

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import BasicCourse from '@/components/Academy/Doctor/BasicCourse';
import BasicCoursePhd from '@/components/Academy/Doctor/BasicCoursePhd';
import CourseTest from '@/components/Academy/Doctor/CourseTest';
import EnglishTest from '@/components/Academy/Doctor/EnglishTest';
import OralExam from '@/components/Academy/Doctor/OralExam';
import ResearchTest from '@/components/Academy/Master/ResearchTest';
import PaperOutcome from '@/components/Academy/Master/PaperOutcome';
import PaperProgress from '@/components/Academy/Doctor/PaperProgress';
import PaperPoints from '@/components/Academy/Doctor/PaperPoints';
import Publish from '@/components/Academy/Doctor/Publish';
export default {
  name: 'academy-doctor',
  components: {
    BasicCourse,
    BasicCoursePhd,
    CourseTest,
    EnglishTest,
    OralExam,
    ResearchTest,
    PaperOutcome,
    PaperProgress,
    PaperPoints,
    Publish,
  },
  data() {
    return {
      editIcon,
      uid: '',
      defaultProfile: require("../../assets/defaultProfile.jpg"),
      publicProfile: {}
    };
  },
  computed: {
    ...mapGetters(['currentUID', 'academicData']),
    dropStatus() {
      const statusMapping = Object.freeze({
        student: '在學',
        drop: '休學',
      });
      return statusMapping[this.academicData.dropout_status];
    },
    grade() {
      const dates = moment().diff(
        moment(this.academicData.entry_year + '-09-12'),
        'days',
      );
      const grade = Math.floor(dates / 365) + 1;
      return grade;
    },
    photo() {
      return `${config.BASEURL}/image/${this.publicProfile.photo}`;
    }
  },
  methods: {
    ...mapActions(['setAcademicData', 'setCourseData', 'setErrorText']),
    async getAcademic() {
      const academic = await this.$services.academic
        .getAcademic('phd', this.uid)
        .catch(e => {
          console.log(e);
          this.$router.push(`/Profile/${this.uid}`);
        });
      console.log('academic data', academic);
      if (academic.basic_courses) {
        academic.basic_courses.forEach((course, idx) => {
          academic.basic_courses[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
        });
      } else {
        academic.basic_courses = [];
      }
      if (academic.qualify_subject_exam) {
        academic.qualify_subject_exam.forEach((course, idx) => {
          academic.qualify_subject_exam[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.qualify_subject_exam[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
          academic.qualify_subject_exam[idx].pass_type =
            course.pass_type === 'exam' ? '考試通過' : '論文抵免';
        });
      } else {
        academic.qualify_subject_exam = [];
      }
      if (academic.oral_exams) {
        academic.oral_exams.forEach((course, idx) => {
          academic.oral_exams[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.oral_exams[idx].is_passed =
            course.is_passed == null ? null : course.is_passed == false ? 0 : 1;
          academic.oral_exams[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
          academic.oral_exams[idx].deadline = moment(course.deadline).format(
            'YYYY-MM-DD',
          );
        });
      } else {
        academic.oral_exams = [];
      }
      if (academic.degree_exam_details) {
        academic.degree_exam_details.forEach((course, idx) => {
          academic.degree_exam_details[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.degree_exam_details[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
          academic.degree_exam_details[idx].deadline = moment(
            course.deadline,
          ).format('YYYY-MM-DD');
        });
      } else {
        academic.degree_exam_details = [];
      }
      if (academic.progress_checks) {
        academic.progress_checks.forEach((course, idx) => {
          academic.progress_checks[idx].is_passed =
            course.is_passed == null ? null : course.is_passed == false ? 0 : 1;
          academic.progress_checks[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.progress_checks[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
        });
      } else {
        academic.progress_checks = [];
      }
      if (academic.proposal_details) {
        academic.proposal_details.forEach((course, idx) => {
          academic.proposal_details[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.proposal_details[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
        });
      } else {
        academic.proposal_details = [];
      }
      if (academic.paper_points) {
        academic.paper_points.forEach((course, idx) => {
          academic.paper_points[idx].confirm =
            course.confirm == null ? null : course.confirm == false ? 0 : 1;
          academic.paper_points[idx].date = moment(course.date).format(
            'YYYY-MM-DD',
          );
        });
      } else {
        academic.paper_points = [];
      }
      console.log('academic data', academic);
      this.setAcademicData(academic);
    },
    async getProfile() {
      const publicProfile = await this.$services.profile.getPublicProfile(
        this.uid,
      );
      this.publicProfile = publicProfile;
    }
  },
  mounted() {
    this.uid = this.$route.params.id;
    this.getAcademic();
    this.getProfile();
  },
};
</script>

<style scoped>
#doctor {
  padding: 33px 16% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;

  height: 100vh;
  overflow: scroll;
}
.page-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.page-title {
  color: var(--v-pageTitle-base);
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.profile-public-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  height: 200px;
  margin-top: 30px;
}

.profile-pic-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.profile-pic-container > img {
  width: auto;
  height: 100%;
  border-radius: 24px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.06);
}

.profile-public-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;

  background-color: var(--v-white-base);
  width: 100%;
  height: 100%;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: 35px 28px;
  margin-left: 20px;

  position: relative;
}

.profile-public-label {
  width: 160px;
  height: 40px;
  position: absolute;
  top: -20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--v-blue-base);
  color: var(--v-white-base);
}

.public-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.info-item {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  /* background-color: yellow; */
}

.item-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 35px;
  color: var(--v-white-base);
  background-color: var(--v-blue-base);
  border-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.item-label-longer {
  width: 100px;
}

.item-content {
  margin-left: 16px;
  font-weight: bold;
  color: var(--v-darkGrey2-base);
  text-align: left;
}

.edit-icon-container {
  width: 35px;
}
.edit-icon {
  width: 100%;
}

/* all tables */
table {
  border: 2px solid var(--v-blue-base);
  border-radius: 24px;
  background-color: #fff;
  color: var(--v-darkGrey2-base);
  width: 100%;
  margin-top: 20px;
}

th {
  background-color: var(--v-blue-base);
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
}

td {
  background-color: #f9f9f9;
}

th,
td {
  min-width: 120px;
  padding: 10px 20px;
}

.top-left-corner {
  border-top-left-radius: 20px;
}
.top-right-corner {
  border-top-right-radius: 20px;
}
.bottom-left-corner {
  border-bottom-left-radius: 20px;
}
.bottom-right-corner {
  border-bottom-right-radius: 20px;
}
.table-note {
  width: 18%;
}
.paper-note {
  width: 35%;
}
.test-name {
  width: 40%;
}
.status-note {
  width: 25%;
}
</style>
