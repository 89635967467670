import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import services from './services';
import Vuex from 'vuex';
import store from './store';
import editor from './plugins/editor'
import VueCookies from 'vue-cookies'
import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueCookies);
Vue.use(editor);
Vue.use(Vuex);
Vue.use(Loading);

Vue.config.productionTip = false;

Vue.use(services, { store });

new Vue({
  router,
  vuetify,
  services,
  store,
  render: h => h(App),
}).$mount('#app');
